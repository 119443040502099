import React, { useRef, useState, useEffect} from 'react';

const LocationAutocomplete = ({ value, onChange, placeholder, disabled }) => {
    const inputRef = useRef(null);
    //console.log(value)
    const [valueDir, setValueDir] = useState('');
    const [tryNow, setTryNow] = useState(null);

    // Función para actualizar el estado en tiempo real
    const handleWrite = (e) => {
        const newValue = e.target.value; // Obtén el nuevo valor del input
        setValueDir(newValue); // Actualiza el estado
    }; 

        // Usamos useEffect para actualizar el estado de tryNow cuando valueDir cambia
        useEffect(() => {
            if (valueDir.length >= 6) {
                //console.log('true');
                setTryNow(true);
            } else {
                //console.log('false');
                setTryNow(false);
            }
        }, [valueDir]); // Dependencia en valueDir para que se ejecute solo cuando cambie
    
        useEffect(() => {
                if (tryNow) {
                    console.log('con este valor hay que trabajar',valueDir)
                
                    if (!window.google) {
                        console.error('Google Maps API no está cargada.');
                        return;
                    }

                    const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
                        componentRestrictions: { country: 'AR' }, // Restringir a Argentina
                        fields: ['formatted_address'], // Solo obtener la dirección completa
                        types: ['address'], // Solo mostrar direcciones completas
                    });
                    console.log(autocomplete)
                    autocomplete.addListener('place_changed', () => {
                        const place = autocomplete.getPlace();
                        // Extraer la dirección y las coordenadas del lugar seleccionado
                        const description = place.formatted_address;
                        //console.log("Coordenadas: ", { lat, lng });
                        
                        // Separar la dirección en partes
                        const addressParts = description.split(',');
                        const domicilio = addressParts[0]?.trim() || '';
                        const ciudadProvincia = addressParts[1]?.trim() || '';
                        const pais = addressParts[2]?.trim() || '';
                        
                        // Separar ciudad y provincia si el formato es esperado
                        let ciudad = '';
                        let provincia = '';
                        if (ciudadProvincia.includes(' Provincia de ')) {
                            [ciudad, provincia] = ciudadProvincia.split(' Provincia de ');
                        } else {
                            ciudad = ciudadProvincia;
                        }
            
                       // console.log({ domicilio, ciudad, provincia, pais });
                        onChange({ domicilio, ciudad, provincia, pais });
                    });

                } else {
                    console.log('esta en false')
                }
        },[tryNow]); 

    return (
        <div className="form-group">
            <input
                ref={inputRef}
                type="text"
                value={ placeholder === 'Domicilio' ? valueDir : value}
                onChange={(e) => !disabled && handleWrite(e)}
                placeholder={placeholder || "Escribe una ubicación"}
                className="form-control"
                disabled={disabled}
                required
            />
        </div>
    );
};

export default LocationAutocomplete;
